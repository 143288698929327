// @ts-nocheck
/* eslint-disable */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable */
/* stylelint-disable */
import { GET_PRODUCTS,GET_SUBCHILDRENS } from "./Brandmall.type";
/** @namespace Store/Brandmall/Reducer/getInitialState */
export const getInitialState = () => ({
    items: [],
    childrens: [],
});

export const BrandmallReducer = (
    state = getInitialState(),
    action,
)=>{
    const { type } = action;

    console.log("type", type);
   
    switch (type) {
            
        case GET_PRODUCTS:
            const { items } = action;
            console.log("Action");
            return {
                ...state,
                   items,
            };
            case GET_SUBCHILDRENS:
                const { childrens } = action;
                return{
                    ...state,
                    childrens,
                }

        default:
            console.log("defalut");
            return state;
        }
   
}
export default BrandmallReducer;



