// @ts-nocheck
/* eslint-disable */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable */
/* stylelint-disable */
import { GET_SUBPRODUCTS} from "./BrandmallSubCategory.type";
/** @namespace Store/Brandmall/Reducer/getInitialState */
export const getInitialState = () => ({
    items: [],
});

export const BrandmallSubCategoryReducer = (
    state = getInitialState(),
    action,
)=>{
    const { type } = action;

    console.log("type", type);
   
    switch (type) {
            
        case GET_SUBPRODUCTS:
            const { items } = action;
            console.log("Action");
            return {
                ...state,
                   items,
            };


        default:
            console.log("defalut");
            return state;
        }
   
}
export default BrandmallSubCategoryReducer;



