/* eslint-disable react/jsx-no-bind */
/* eslint-disable */
/* stylelint-disable */ 

import { connect } from 'react-redux';
import {
    RouterContainer as SourceRouterContainer,
    mapDispatchToProps,
    mapStateToProps
} from 'SourceComponent/Router/Router.container';
import { DEMO_NOTICE_HEIGHT } from 'Component/Router/Router.config';

/** @namespace Component/Router/Container */
export class RouterContainer extends SourceRouterContainer {
    setDemoNoticeHeight() {
        const {
            demo_notice,
        } = this.props;
        
       let DemoNotice = demo_notice?.toString() == '0' ? '0' : DEMO_NOTICE_HEIGHT;

        if (demo_notice) {
            const root = document.querySelector(':root');
            root?.style.setProperty('--demo-notice-height', `${DemoNotice}px`);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouterContainer);
