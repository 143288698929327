// @ts-nocheck
/* eslint-disable */

import { 
    UPDATE_REGIONS,
    IS_LOADING,
    UPDATE_CITIES,
    SAVED_COOKIES
} from "./CityPricing.type";


export const getInitialState = () => ({
    isLoading: false,
    regions: [],
    cities: [],
    savedCookie: {}
});

export const CityPricingReducer = (
    state= getInitialState(),
    action,
) => {
    const {
        type
    } = action;

    switch (type) {
        case UPDATE_REGIONS:

            const {regions} = action;

            return {
                ...state,
                regions
            };

        case UPDATE_CITIES:

            const {cities} = action;

            return {
                ...state,
                cities
            };

        case IS_LOADING:
            const { isLoading } = action;

            return {
                ...state,
                isLoading,
            };

        case SAVED_COOKIES:
            const { savedCookie } = action;

            return {
                ...state,
                savedCookie
            };

        default:
            return state;
    }
};

export default CityPricingReducer;


