// @ts-nocheck
/* eslint-disable */

import {
    UPDATE_IS_ADDING_PRODUCT_FBT
} from './ProductFbt.type';

/** @namespace Store/ProductFbt/Reducer/getInitialState */
export const getInitialState = () => ({
    isAdding: false
});

/** @namespace Store/ProductFbt/Reducer/ProductFbtReducer */
export const ProductFbtReducer = (
    state = getInitialState(),
    action,
) => {
    const { type } = action;

    switch (type) {

        case UPDATE_IS_ADDING_PRODUCT_FBT:
            const { isAdding } = action;

            return {
                ...state,
                isAdding,
            };

        default:
            return state;
        }
};

export default ProductFbtReducer;
