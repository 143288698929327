// @ts-nocheck
/* eslint-disable */

import { 
    STORE_LOCATOR_UPDATE_CONFIG,
    STORE_LOCATOR_IS_LOADING,
    STORE_LOCATOR_UPDATE_STORES_LIST,
    STORE_LOCATOR_TOTAL_STORES
} from "./StoreLocator.type";


export const getInitialState = () => ({
    isLoading: false,
    storeConfig: {},
    storeList: [],
    totalStores: 0
});

export const StoreLocatorReducer = (
    state= getInitialState(),
    action,
) => {
    const {
        type
    } = action;

    switch (type) {

        case STORE_LOCATOR_UPDATE_CONFIG:
            const { config } = action;

            return {
                ...state,
                storeConfig: config,
            };

        case STORE_LOCATOR_IS_LOADING:
            const { isLoading } = action;

            return {
                ...state,
                isLoading,
            };

        case STORE_LOCATOR_UPDATE_STORES_LIST:
            const { list } = action;

            return {
                ...state,
                storeList: list
            };

        case STORE_LOCATOR_TOTAL_STORES:
            const { count } = action;

            return {
                ...state,
                totalStores: count
            };

        default:
            return state;
    }
};

export default StoreLocatorReducer;


