// @ts-nocheck
/* eslint-disable */

import {
    GET_MP_REVIEWS,
    UPDATE_IS_LOADING_MP_REVIEWS,
    PAGE_INFO_MP_REVIEWS,
    TOTAL_PAGE_MP_REVIEWS
} from './MpReviews.type';

/** @namespace Store/MpReviews/Reducer/getInitialState */
export const getInitialState = () => ({
    isLoading: false,
    pageInfo:{},
    totalPage: 0,
    reviews: [],
});

/** @namespace Store/MpReviews/Reducer/MpReviewsReducer */
export const MpReviewsReducer = (
    state = getInitialState(),
    action,
) => {
    const { type } = action;

    switch (type) {
        case GET_MP_REVIEWS:

            const { reviews } = action;
            const { reviews: oldReviews } = state;

            const finalReviews = oldReviews.concat(reviews);

            return {
                ...state,
                reviews: finalReviews
            };

        case UPDATE_IS_LOADING_MP_REVIEWS:
            const { isLoading } = action;

            return {
                ...state,
                isLoading,
            };

        case PAGE_INFO_MP_REVIEWS:
            const { pageInfo } = action;

            return {
                ...state,
                pageInfo,
            };

        case TOTAL_PAGE_MP_REVIEWS:
            const { totalPage } = action;

            return {
                ...state,
                totalPage,
            };

        default:
            return state;
        }
};

export default MpReviewsReducer;
